import { Box, VStack, Heading, Text, Badge, Image, useColorModeValue, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import certifications from './certs.json'; 
import { FullScreenSection } from "../../components";

function Certification() {
  const bgColor = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");

  return (
    <FullScreenSection id="certifications"
      isDarkBackground 
      py="12"
      background="linear-gradient(to bottom, cyan 0%,#171923 70%, black 100%)"
    >
      <Box className="certification" color="white" px="4" py="24" mb={6}  textAlign="center" maxW="60%" >
      <Heading as="h1" size="xl">
        Certifications  &amp; Degrees 
      </Heading>
      <Heading as="h3" size="sm" title="certificationDescription">
      I acquired key certifications in Generative AI Fundamentals, Meta Front-End Development, and Microsoft Azure Fundamentals. These validate my expertise in AI, front-end programming, and cloud services, respectively, amplifying my competitiveness in the software engineering field.
      </Heading>
      </Box>
      <VStack spacing={10} align="stretch">
        {certifications.map((cert, index) => (
          <Box
            key={cert.id}
            p={5}
            shadow="md"
            alignSelf="center"
            borderWidth={1}
            borderRadius="md"
            bgColor={bgColor}
            cursor="pointer"
            transition="all 0.3s ease"
            width={{ base: "250px", md: "500px", lg: "750px" }}
            height={{ base: "400px", md: "250px", lg: "300px" }}

            _hover={{
              transform: 'scale(1.25)',
              border: '8px solid teal',
              boxShadow: '0 0 8px teal',
            }}
          >
            <Image
              boxSize="100px"
              src={cert.logo}
              alt={cert.title}
              mb={2}
            />
            <Badge borderRadius="full" px={2} colorScheme="teal">
              {new Date(cert.date).getFullYear()}
            </Badge>
            <Heading size="md"  color={color} fontWeight="bold" mt={2}>
              {cert.title}
            </Heading>
            <Text color={color}>{cert.description}</Text>
            {/* <span color={color}>{cert.credential}</span> */}
            <Box d="flex" mt={2} alignItems="center">
              <FontAwesomeIcon icon={faCheckCircle} color="green.500" />
              <Text ml={1} fontSize="sm" color={color}>
                Completed {"\u2705"}
              </Text>
            </Box>
          </Box>
        ))}
      </VStack>
    </FullScreenSection>
  );
}

export default Certification;
