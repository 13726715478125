import React, { useState } from 'react';
import "./landing.css"
import {
  Avatar,
  Box,
  Button,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react';
import FullScreenSection from "../fullscreen/FullScreenSection";


const qrcode = require('../../images/qrcode.png');
const greeting = <p style={{ fontFamily: "sans-serif" }}>Hello there! I'm Thomas</p>;

const bio1  = (
  <Box className="wobble">
    <Text as="span" fontWeight="bolder" fontSize="3xl">C</Text>reating creative software
  </Box>
);

const bio2 =  (
  <Box className="wobble">
    <Text as="span" fontWeight="bolder" fontSize="3xl">O</Text>rchestrating optimal outcomes
  </Box>
);

const bio3 =  (
  <Box className="wobble">
    <Text as="span" fontWeight="bolder" fontSize="3xl">D</Text>iving deep into data
  </Box>
);

const bio4 =  (
  <Box className="wobble">
    <Text as="span" fontWeight="bolder" fontSize="3xl">E</Text>mbracing the evolution of tech
  </Box>
);
const handleModalOpen = (setIsModalOpen) => () => setIsModalOpen(true);
const handleModalClose = (setIsModalOpen) => () => setIsModalOpen(false);

const VideoPlayer = ({ videoStyle }) => (
  <video 
  id="background-landing" 
  loop 
  muted 
  playsInline 
  autoPlay 
  style={videoStyle}  
  preload="auto"
  
  
  >
    {/* <source type="video/mp4; codecs=avc1.4D401E,mp4a.40.2" src="https://github.githubassets.com/images/modules/site/home/aurora.h264.mp4" /> */}
    <source type="video/mp4; codecs=avc1.4D401E,mp4a.40.2" 
    src = "https://github.githubassets.com/images/modules/site/home/aurora.h264.mp4"
    />
  </video>
);

const ModalBox = ({ handleModalClose }) => (
  <Box position="fixed" top={0} left={0} right={0} bottom={0} zIndex={9999} background="rgba(0, 0, 0, 0.5)" display="flex" justifyContent="center" alignItems="center">
    <Box background="white" padding={8} borderRadius="md" boxShadow="lg" textAlign="center">
    <img src={qrcode}
    alt="QR Code: Portfolio website" 
    width={150} />
      {/* <Text fontSize="sm" marginTop="5">QR CODE</Text> */}
      <Button onClick={handleModalClose} marginTop="5" padding="5" color="white" background="gray.900" > Close</Button>
    </Box>
  </Box>
);
const LandingSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmallScreen] = useMediaQuery("(max-width: 600px)");
  const avatarSize = useBreakpointValue({ base: "2xl", md: "xl", lg: "2xl", xl: "3xl" });
  const videoStyle = {
    minWidth: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: -1,
    minHeight: "100%",
    width: "auto",
    height: "auto",
    objectFit: "cover",
    objectPosition: "center",
  };


  const textStyle = {
    color: "white",
    fontSize: "lg",
    marginX: isSmallScreen ? "auto" : "15px",
    textAlign: isSmallScreen ? "center" : "left",
  };

  const bioStyle = {
    color: "white",
    fontSize: "lg",
    marginTop: "1px",
    marginX: isSmallScreen ? "auto" : "15px",
    textAlign: isSmallScreen ? "center" : "left",
  };
  return (
    <FullScreenSection id="Home" justifyContent="center" alignItems="center" position="relative">
      <VideoPlayer videoStyle={videoStyle} />

      <VStack spacing={8} alignItems="center">
        <Box as="button" onClick={handleModalOpen(setIsModalOpen)} _hover={{ filter: "brightness(120%)" }} _active={{ filter: "brightness(150%)" }}>
          <Avatar id="home" src="/Thomas.png" size={avatarSize} name="Thomas" alt="This is my profile picture" />
          <Heading as="h1" size="xl" color="white">{greeting}</Heading>
        </Box>

        <Text {...bioStyle}>{bio1} {bio2} {bio3} {bio4}</Text>

      </VStack>

      {isModalOpen && <ModalBox handleModalClose={handleModalClose(setIsModalOpen)} />}
    </FullScreenSection>
  );
};

export default LandingSection;
