import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Box, Progress, Center, Heading, Text, Image } from "@chakra-ui/react";
import { Highlight } from "@chakra-ui/react";

const Splash = () => {
  let navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/home");
    }, 9000); // 9 seconds

    const progressTimer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(progressTimer);
        }
        return Math.min(oldProgress + Math.ceil((100 - oldProgress) / 10), 100);
      });
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(progressTimer);
    };
  }, [navigate]);

  const getTextContrastColor = (backgroundColor) => {
    return backgroundColor.startsWith('#2C3E50') ? 'white' : 'black';
  };
  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  const rotateAnimation = {
    rotate: [0, 720],
    transition: {
      repeat: Infinity,
      ease: "linear",
      duration: 15,
    
    },
  };

  
  

  const backgroundAnimation = {
    animate: {
      background: [


        "linear-gradient(135deg, #004D4C 0%, #003366 50%, #001B2E 100%)", // Dark Teal to Dark Blue
        "linear-gradient(135deg, #2C3E50 0%, #34495E 50%, #2C3E50 100%)", // Dark Blue Shades
        "linear-gradient(135deg, #0B3B40 0%, #1C2938 50%, #12262F 100%)", // Deep Ocean Blues
        "linear-gradient(135deg, #263238 0%, #344955 50%, #4E6571 100%)", // Slate Grey to Dark Blue
        "linear-gradient(135deg, #1B262C 0%, #0F3460 50%, #16213E 100%)"  // Charcoal to Navy Blue
      
      ],
      transition: {
        repeat: Infinity,
        repeatType: "flip",
        duration: 10,
        ease: "linear",
      },
    },
  };

  return (
    <motion.div
      variants={backgroundAnimation}
      animate="animate"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Center h="100vh">
          <Box width="100%">
            <Box
              position="relative"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              width="full"
              height="350px"
            >
              <motion.div animate={rotateAnimation}>
                <Image
                  src="/circlepixel.png"
                  alt="large logo"
                  boxSize="350px"
                  m="auto"
                />
              </motion.div>
              <Image
                src="/mac.png"
                alt="small logo"
                boxSize="150px"
                position="absolute"
              />
            </Box>

            {/* <Heading
  lineHeight="tall"
  color="gray.200" // Lighter font color for the heading
  fontFamily="RetroFont, cursive"
  fontSize="3xl"
  // fontWeight="extrabold"
  mt="6"
>
  Welcome to My Digital Space! 👋
</Heading> */}
            <Text color="gray.200" fontSize="2xl" mb="2">
              {/* Hello, I'm Thomas. Glad you could make it. 🙂 */}
            </Text>
            <Text
              px="10"
              py="5"
              
              color="gray.200"
              fontSize="xl"
              fontWeight="semibold"
              fontFamily="fantasy"
            >
              <Highlight
                query={[
                  "Software Engineer",
                  "Innovation",
                  "Passionate",
                  "Learning",
                  "Craft",
                ]}
                styles={{
                  px: "2",
                  py: "1",
                  rounded: "full",
                  bgGradient: "linear(to-r, teal.300, blue.500)",
                  color: "black",
                  fontSize: "2xl",
                  fontWeight: "lighter",
                  fontFamily:
                    "San Francisco, Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >
                Hello! I am a Software Engineer whose passionate about
                continuous learning and Innovation. I love to craft impactful and new solutions.
              </Highlight>
              
            </Text>
            <Progress
              colorScheme="green"
              size="sm"
              value={progress}
              hasStripe
              isAnimated
              borderRadius="lg"
              boxShadow="lg"
              // marginTop="6"
            />
            
          </Box>
          
        </Center>
      </motion.div>
    </motion.div>
  );
};

export default Splash;
