import React, { useEffect } from 'react';


const hideTextLayer = () => {
    useEffect(() => {
        const textLayerElements = document.querySelectorAll('.react-pdf__Page__textContent');
        textLayerElements.forEach((element) => {
            element.style.display = 'none';
        });
    }, []);
};

export default hideTextLayer;
