import { ChakraProvider, ColorModeProvider } from "@chakra-ui/react";
import { AlertProvider } from "./context/alertContext";
import Routes from './Routes';

function App() {
  return (
    <ChakraProvider>
      <ColorModeProvider options={{ useDefaults: true }}>
        <AlertProvider>
            <Routes/>
        </AlertProvider>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default App;
