import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import { EmailShareButton } from 'react-share';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShareFromSquare, faTimes, faSearchPlus,faSearchMinus, faUndo } from '@fortawesome/free-solid-svg-icons';
import resumePdf from "./ThomasBacha.pdf"
import hideTextLayer from '../../hooks/useHideTextLayer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import './styles.css';

// const resumePdf = "";
const Resume = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [zoom, setZoom] = useState(1.0);
    const [buttonsClass, setButtonsClass] = useState("buttons-hidden");

    const handleZoomIn = () => {
        setZoom(zoom * 1.1);
    };
    const handleZoomOut = () => {
        if (zoom > 1) {
            setZoom(zoom / 1.1);
        }
    };
    
    const handleResetZoom = () => {
        setZoom(1);
    };
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleDownload() {
        saveAs(resumePdf, 'thomasbacha-Resume.pdf');
    } 

    useEffect(() => {
        if (modalIsOpen) {
            setButtonsClass("buttons-visible");
        } else {
            setButtonsClass("buttons-hidden");
        }
    }, [modalIsOpen]);


    return (
        <div>
            <button id="open-resume" onClick={openModal}>Resume</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Resume Modal"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '80%',
                    },
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' , scale:'1' }}>
                    {/* Zoom controls */} 
                    <button onClick={handleZoomIn}>
                        <FontAwesomeIcon icon={faSearchPlus} /> Zoom In
                    </button>
                    <div style={{ marginLeft: '5px', marginRight: '10px' }}></div>
                    <button onClick={handleZoomOut}>
                        <FontAwesomeIcon icon={faSearchMinus} /> Zoom Out
                    </button>
                    <div style={{ marginLeft: '5px', marginRight: '10px' }}></div>
                    <button onClick={handleResetZoom}>
                        <FontAwesomeIcon icon={faUndo} /> Reset Zoom
                    </button>
    
                    <div style={{ marginLeft: '5px', marginRight: '5px' }}></div>
                    <button onClick={handleDownload}>
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </button>
    
                    <div style={{ marginLeft: '10px', marginRight: '5px' }}></div>
    
                    <EmailShareButton subject="Check out my resume!" body="Here's the latest version of my resume:" url={window.location.href}>
                        <FontAwesomeIcon icon={faShareFromSquare} /> Share
                    </EmailShareButton>
    
                    <div style={{ marginLeft: '10px', marginRight: '5px' }}></div>
    
                    <button onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </button>
                </div>
    
                <div style={{ display: 'flex', justifyContent: 'center'  }}>
                    <Document file={resumePdf} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={1} size="A4" width={window.innerWidth / 2 * zoom} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0 }}>
                        </Page>
                    </Document>
                    {hideTextLayer()}
                </div>
    
            </Modal>
        </div>
    );
};

export default Resume;
