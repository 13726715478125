import { background, border, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Box} from "@chakra-ui/react"



const Card = ({ title, description, imageSrc, githubURL}) => {
  const handleClick = () => {
    window.open(githubURL, "_blank");
  };

  return (
    // <Box borderWidth="1px" borderRadius="lg" overflow="hidden" onClick={handleClick} 
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden"  
      _hover={{
        cursor: "pointer",
        borderColor: "teaGreen.500",
        boxShadow: "xl",
        transform: "scale(1.05)",
        boxShadow: '0 0 10px 5px #fff2ff', 
        
        
        }}>
      <Image 
          src={imageSrc} 
          alt={title} 
          objectFit={"cover"} 
          height={"250px"} 
          width={"100%"} 
      />
      <Box p="6">
        <Heading as="h4" size="md" mb="2">
          {title}
        </Heading>
        <Text fontSize="md" color={"whiteAlpha.900"}>
          {description}
        </Text>
        <HStack justifyContent="flex-end" mt="15" spacing="10px">
          {/* <FontAwesomeIcon icon={faArrowRight}/> */}
        </HStack>
      </Box>
    </Box>
  );
};

export default Card;


