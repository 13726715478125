import * as React from "react";
import { VStack } from "@chakra-ui/react";

const FullScreenSection = ({ children, isDarkBackground, ...boxProps }) => {

  return (
    <VStack
      color={isDarkBackground ? "white" : "black"}
      minHeight="100vh"
      minWidth="100vw"
      width="100%"
      // maxW="none"
      {...boxProps}
    >
      {children}
    </VStack>
  );
};

export default FullScreenSection;
