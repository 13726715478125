import React, { useState } from "react";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { Link } from 'react-scroll';
import "./Footer.css";

const commonLinkStyle = {
  display: 'block',
  transition: "all 0.3s ease",
  margin: "10px 0px 5px 0px",
};

const hoverStyle = {
  transform: "scale(1.5)",
  textDecoration: "underline",
  color: "white"
};

const CustomLink = ({ href, to, children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const style = isHovered ? { ...commonLinkStyle, ...hoverStyle } : commonLinkStyle;
  const commonProps = {
    style,
    onMouseEnter: () => {
      document.body.style.cursor = 'pointer';
      setIsHovered(true);
    },
    onMouseLeave: () => {
      document.body.style.cursor = 'default';
      setIsHovered(false);
    }
  }

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...commonProps}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={to}
        smooth={true}
        duration={1000}
        {...commonProps}
      >
        {children}
      </Link>
    );
  }
};

const FooterAbout = () => (
  <Box
    width="100vw"
    background="linear-gradient(to right, black 0%, #18181b 100%)"
  >
    <footer>


      <Flex id="link" justifyContent="space-between" alignItems="center">

      <Box as="p" >

          <h1><strong>Technical</strong></h1>
          <CustomLink to="projects"  >Projects</CustomLink>
          <CustomLink to="AI-Assistant">Assistant</CustomLink>
          <CustomLink to="tech-stack" >Technologies</CustomLink>
          <CustomLink href="https://github.com/ThomasBacha">GitHub</CustomLink>

        </Box>

        <Box as="p" >
          <h1><strong>Achievements</strong></h1>
          <CustomLink to="certifications" >Certifications</CustomLink>
          <CustomLink to="Timeline">Timeline</CustomLink>
          {/* <CustomLink to="tech-stack" >    </CustomLink> */}
        </Box>
        <Box as="p" >

          <h1><strong>Personal</strong></h1>
          <CustomLink href="mailto:thomasd.bacha@gmail.com" >Email</CustomLink>
          <CustomLink href="https://www.linkedin.com/in/thomasbacha">LinkedIn</CustomLink>
          <CustomLink href="https://t.me/thomas_bacha">Telegram</CustomLink>

        </Box>

        <Box as="p">
          <h1><strong>Legal</strong></h1>
          <CustomLink href="/about/privacy-policy" >Privacy Policy</CustomLink>
          <CustomLink href="/about/terms-of-use">Terms of Use</CustomLink>
          <CustomLink href="/about/Testimonial">Disclosure</CustomLink>
        </Box>

      </Flex>
    </footer>
  </Box>
);

export default FooterAbout;
