import React from 'react';

const Testimonials = () => {
  return (
    <div style={{ 
      width: "100vw", 
      height: "100vh", 
      backgroundColor: "black", 
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center" 
    }}>
      <div style={{ color: "white", textAlign: "center", justifySelf:"center", maxWidth: "35%" }}>
        <h1><strong>Testimonials</strong></h1>
        {/* <img src = "https://logowik.com/content/uploads/images/twitter-x5265.logowik.com.webp" alt = "Twitter Logo" width = "50px" height = "50px" style={{display:"block",margin:"auto"}} alignItems= "center"  /> */}
        <p>
          Coding my Portfolio and implementing the projects showcased in my portfolio have been pivotal in my professional growth. 
          They've not only enriched my skill set but also opened up innovative problem-solving avenues, 
          enabling me to create solutions that both challenge and inspire me.
          
        </p>
      </div>
    </div>
  );
};

export default Testimonials;
