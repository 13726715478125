import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Box, Input, Button, VStack, Heading, Text } from '@chakra-ui/react';

const Age = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [weeks, setWeeks] = useState(0);
    const [years, setYears] = useState(0);

    const calculateYears = () => {
        if (startDate && endDate) {
            const start = DateTime.fromISO(startDate);
            const end = DateTime.fromISO(endDate);
            const yearsDifference = end.diff(start, 'years').years;
            // round to nearest whole number
            setYears(Math.round(yearsDifference));
        }
    };


    const calculateWeeks = () => {
        if (startDate && endDate) {
            const start = DateTime.fromISO(startDate);
            const end = DateTime.fromISO(endDate);
            const weeksDifference = end.diff(start, 'weeks').weeks;
            // round to nearest whole number
            setWeeks(Math.round(weeksDifference));
        }
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const totalcalcuation = () => {
        calculateWeeks();
        calculateYears();
    };


    const gradientBackgroundStyle = {
        backgroundImage: 'linear-gradient(to bottom, #e66465, #9198e5)',
        /* Replace the colors #e66465 and #9198e5 with your desired gradient colors */
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={gradientBackgroundStyle}   >
            <VStack spacing={4}>
                <Heading>Date to Date Calculator</Heading>
                <label htmlFor="startDate">Enter Start Date:</label>
                <Input width="auto" type="date" onChange={handleStartDateChange} />
                <label htmlFor="endDate">Enter End Date:</label>
                <Input width="auto" type="date" onChange={handleEndDateChange} />
                <Button onClick={totalcalcuation}> Calculate</Button>
                {weeks !== 0 && (
                    <Box textAlign="center" p={4} bg="purple.100" borderRadius="lg" width="700px">
                        <Text fontSize="3xl" fontWeight="bold" color="purple.800">
                            Approximately:
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                            ------------------------
                        </Text>

                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {years} years
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                        that is
                        </Text>


                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {weeks} weeks
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                        that is
                        </Text>
                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {weeks * 7} days
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                        that is
                        </Text>
                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {weeks * 7 * 24} hours
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                        that is
                        </Text>
                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {weeks * 7 * 24 * 60} minutes
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                        that is
                        </Text>
                        <Text fontSize="4xl" fontWeight="bold" color="purple.600">
                            {weeks * 7 * 24 * 60 * 60} seconds
                        </Text>
                        <Text fontSize="2xl" fontWeight="bold" color="purple.800">
                            ------------------------
                        </Text>


                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <table
                                style={{
                                    fontFamily: "consolas",
                                    fontSize: "20px",
                                    color: "purple",
                                    borderCollapse: "collapse",
                                    marginLeft: "auto",
                                    marginRight: "auto",

                                }}
                            >
                                <tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Years</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{years}</td>
                                </tr>
                                <tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Weeks</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{weeks}</td>
                                </tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Days</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{weeks * 7}</td>
                                <tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Hours</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{weeks * 7 * 24}</td>
                                </tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Minutes</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{weeks * 7 * 24 * 60}</td>
                                <tr>
                                    <th style={{ backgroundColor: "purple", color: "white", padding: "10px", border: "1px solid purple" }}>Seconds</th>
                                    <td style={{ padding: "10px", border: "1px solid purple" }}>{weeks * 7 * 24 * 60 * 60}</td>

                                </tr>
                                
                            </table>
                        </div>

                    </Box>
                )}
            </VStack>
        </div>
    );
};

export default Age;
