import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import FullScreenSection from "../fullscreen/FullScreenSection";

const Footer = () => {
  return (
    <Box
      // backgroundColor="#18181b"
      background="linear-gradient(to right, black 0%, #18181b 100%)"
      width="100vw"
    >
<footer>
  <Flex
    margin="0 auto"
    px={12}
    color="white"
    justifyContent="center"
    alignItems="center"
    height={16}
  >
  <div  style={{ marginBottom:"50px", textAlign: "center"  }}>
    <p style={{ marginBottom: "20px" }}>&copy; 2023 Thomas Bacha. All Rights Reserved.</p>
    <p>This website was handcrafted with love  <span style={{color: "red"}}>&#x2665;</span> using React, built entirely from scratch</p>
    
  </div>
  </Flex>
</footer>

    </Box>
  );
};
export default Footer;
