import React from "react";
import FullScreenSection from "../fullscreen/FullScreenSection";
import {useBreakpointValue, Box, Heading } from "@chakra-ui/react";
import Card from "../card/Card";

const projects = [

  {
    title : "Mass Emailer Tool",
    description: "This innovative project is a fusion of technology and business acumen aimed at increasing organizational efficiency. The tool automates the process of email distribution, thereby drastically reducing time and resource expenditure. As a result, employees can channel their efforts towards more strategic tasks.",
    getImageSrc: () => "https://img.freepik.com/premium-vector/automation-software-iot-automation-concept-wireframed-cyborg-hand-touching-digital-interface_127544-1197.jpg",
    githubURL: "",
    
    },
    {
    title: "React portfolio web application",
    description: "This portfolio web application, built using React.js, showcases professional experiences, projects, and skills. It's an interactive digital resume providing an insightful, visual presentation of one's career journey, thus enhancing professional visibility.",
    getImageSrc: () => "https://images.unsplash.com/photo-1453090643118-eae60f5bc58a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTQzfHx0eXBld3JpdGVyJTIwd2l0aCUyMGNvZGV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    githubURL: "",
    },
    
    {
    title: "Timesheet Tool",
    description: "A time-management tool designed to streamline the tracking of hours spent on various tasks. It enhances organizational efficiency by automating timesheet handling, reducing administrative burdens, and allowing teams to focus more on their core tasks.",
    getImageSrc: () => "https://www.productdossier.com/wp-content/uploads/2020/10/Timesheet.jpg",
    githubURL: "",
    },
    
    {
    title: "Job Feed Compliance Tool",
    description: "This unique tool automates the process of compliance checks, fostering professional growth and enhancing the marketability of staff. It efficiently manages the workload of staffing partners while promoting a culture of continuous learning.",
    getImageSrc: () => "https://www.rappahannockit.com/wp-content/uploads/2018/09/Compliance-700x400.jpg",
    githubURL: "",
    },
    {
    title: "Chatbot and Excel Data Analysis",
    description: "This project presents a custom-built Streamlit application that integrates a robust chatbot and an Excel data analysis tool. By optimizing daily tasks, it exemplifies the power of AI, data analysis, and application development in boosting productivity.",
    getImageSrc: () => require("../../images/streamlit.png"),
    githubURL: "",
    },
    {
    title: "Xlsb To Xlsx Converter",
    description:
    "This tool, developed in Python, enables the transformation of Excel Binary (.xlsb) files into Open XML Spreadsheet (.xlsx) format, facilitating seamless data handling and processing.",
    getImageSrc: () => require("../../images/TransformerImage.png"),
    githubURL: "",
    },
    {
    title: "Unreal Engine 5 Metahuman",
    description:
    "This project involves the creation of an engaging metaverse using Unreal Engine 5. It allows users to interact socially with personalized avatars that closely resemble their real-life appearances, adding a unique dimension to virtual communication.",
    getImageSrc: () => require("../../images/ue5demo.png"),
    githubURL: "",
    },

    {
      title: "My Drinking Buddy",
      description: "This mobile application serves as a dependable companion for responsible drinking habits. It assists users in making informed decisions about alcohol consumption, thereby ensuring their safety and promoting conscious enjoyment.",
      getImageSrc: () => require("../../images/android-app.png"),
      githubURL: "",
      }

];

// align to center
const ProjectsSection = () => {

  const templateColumns = useBreakpointValue({
    base: "repeat(1,minmax(0,1fr))",
    sm: "repeat(2, minmax(0,1fr))",
    md: "repeat(3, minmax(0,1fr))",
    lg: "repeat(3, minmax(0,1fr))",
    xl: "repeat(3, minmax(0,1fr))",

  });
  // repeat(3,minmax(0,1fr))
  return (
    <FullScreenSection id = "projects"
      // backgroundColor="#567ea9 "
      background = "linear-gradient(180deg, rgba(86,126,169,1) 0%,rgba(86,126,169,1) 30%,#11113e 75%, #354e69 90%, cyan 100%)"
      bgSize="cover"
      isDarkBackground
      p={8}
      alignItems="flex-start"

      spacing={8}
      justifyContent="center"
    >
      <Heading
        as="h1"
        size="xl"
        color="whiteAlpha.900"
        alignSelf={"center"}
        mb={8}
        mt={8}
      >
        Projects
      </Heading>

      <Box

        title="Projects"
        display="grid"
        width={"80%"}
        alignSelf={"center"}
        gridTemplateColumns={templateColumns}
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            githubURL={project.githubURL}

          />
        ))}


      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;