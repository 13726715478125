import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ 
      width: "100vw", 
      height: "100vh", 
      backgroundColor: "black", 
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center" 
    }}>
      <div style={{ color: "white", textAlign: "center", maxWidth: "35%" }}>
        <h1><strong>Privacy Policy</strong></h1>
        <p>
        We are committed to respecting your privacy. We do not collect, store, or process any personal data from our users.
        You can use our webapp without providing any personal information. 
        We do not use cookies or tracking technologies. 
        If you have any questions or concerns about our privacy practices, please contact us at <span style={{textDecoration: 'underline'}}>Thomasbacha.dev@gmail.com</span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

