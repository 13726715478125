import React from 'react';
import {Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { PrivacyPolicy, TermsOfUse,Testimonial} from './components';
import Age from './components/LifeAgeCalculator/Age';
import Splash  from './components/SplashScreen/Splash';
import Home from './Home';

const AppRoutes = () => {
    return (
            <Routes>
                <Route path="/Welcome" element={<Splash />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about/terms-of-use" element={<TermsOfUse />} />
                <Route path="/about/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about/Testimonial" element={<Testimonial />} />
                <Route path="/secret/calculator" element={<Age />} />
                <Route path="*" element={<Navigate to="/Welcome" />} />

            </Routes>
    );
}

export default AppRoutes;
