

import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin, faYoutube, faPage4, faTelegram, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { Box, HStack, Spacer, useBreakpointValue } from "@chakra-ui/react";
import { Resume} from "../index";
import { Link } from "react-scroll";
import "./Header.css";
import DarkModeSwitch from "../../hooks/useColorMode";

// import gsap from "gsap";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
const socials = [
  {
    icon: faEnvelope,
    url: "mailto: Thomasbacha.dev@gmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/ThomasBacha/",
  },
  {
    icon: faTelegramPlane,
    url: "https://t.me/thomas_bacha",

  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/thomasbacha",
  },
  // {
  //   icon: faYoutube,
  //   url: "https://www.youtube.com/",
  //   // channel/UCSmMz28ksvaLjmQAh8NRZSg
  // },
  

];

const Header = () => {
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const headerElement = document.getElementById("header");

      // Make sure the element exists before trying to access its style property
      if (headerElement) {
        if (prevScrollY.current < currentScrollY) {
          // Scrolling down
          headerElement.style.transform = "translateY(-10px)";
        } else {
          // Scrolling up
          headerElement.style.transform = "translateY(0)";
        }

        prevScrollY.current = currentScrollY;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const iconSize = useBreakpointValue({ base: "1x", md: "2x" }); 
  const spacing = useBreakpointValue({ base: "2", md: "5" });
  const projectNavSpacing = useBreakpointValue({ base: "2", md: "5" }); 
  
  return (
    <Box
      id="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      background="linear-gradient(90deg, rgba(24,24,27,1) 0%, rgba(24,24,27,1) 50%, rgba(24,24,27,0.5) 100%)"
      zIndex={1}
    >
      <Box color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={16}
          py={4}
          justifyContent="space-between"
          alignItems="center"
          spacing={spacing}
        >
          <nav>
            <HStack spacing={spacing}>
              {socials.map((social, index) => (
                <a 
                key={index} 
                href={social.url} 
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={social.icon} size={iconSize} />
                </a>
              ))}
            </HStack>
          </nav>

          <nav className="Project-header-scroll">
            <HStack spacing={projectNavSpacing}
            
            >
              
              <Resume />

              <Link
                to="projects"
                smooth={true}
                duration={500}
                onMouseEnter={() => document.body.style.cursor = 'pointer'}
                onMouseLeave={() => document.body.style.cursor = 'default'}
              >
                Projects
              </Link>
              
              {/* <Link
                to="AI-Assistant"
                smooth={true}
                duration={500}
                onMouseEnter={() => document.body.style.cursor = 'pointer'}
                onMouseLeave={() => document.body.style.cursor = 'default'}
              > 
                Assistant
              </Link> */}

              <Link
                to="Home"
                smooth={true}
                duration={500}
                onMouseEnter={() => document.body.style.cursor = 'pointer'}
                onMouseLeave={() => document.body.style.cursor = 'default'}
                
              >
                <FontAwesomeIcon icon={faHouse} size={iconSize} />
                </Link>

                
                <DarkModeSwitch/>


            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};

export default Header;
