import { Button, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const DarkModeSwitch = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const Moon = <FontAwesomeIcon icon={faMoon} size='2x'  />;
    const Day  = <FontAwesomeIcon icon={faLightbulb} size='2x' color="white" />;
    const icon = colorMode === 'light' ? Moon : Day ; 
    // return (
    //     // <Button className='Adjust-dark-mode' onClick={toggleColorMode} variant="ghost" color="white"  fontWeight="bold" aria-label="Toggle dark mode">
    //     // {icon}
    //     // </Button>
    //     col            
    //     );
};

export default DarkModeSwitch;
