import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; 
import { FullScreenSection } from "../../components";
import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const ResumeTimeline = () => {

const experiences = [
    
      {
        "title": "Unreal Engine 5 Metahuman (UE5MH)",
        "date": "February 2023",
        "description": "Embarked on a groundbreaking project harnessing the capabilities of Unreal Engine 5 to create a metaverse for social interaction with personalized avatars."
      },
      {
        "title": "Timesheet Tool (TST)",
        "date": "March 2023",
        "description": "Developed an automated Timesheet Tool leveraging Python and Pandas. This tool streamlines the email distribution process, reduces human error, and increases organizational efficiency."
      },
      {
        "title": "Chatbot and Excel Data Analysis Application",
        "date": "March 2023",
        "description": "Created a multi-purpose Streamlit application integrating a chatbot and an Excel data analyzer, displaying expertise in AI, data analysis, and app development."
      },
      {
        "title": "Xlsb To Xlsx Converter Tool (SB2SX) ",
        "date": "April 2023",
        "description": "Developed a Python-based tool for converting Excel Binary (.xlsb) files to Open XML Spreadsheet (.xlsx) format, simplifying data handling for teams."
      },

      {
        "title": "Job Feed Compliance Tool",
        "date": "May 2023",
        "description": "Introduced a Job Feed Compliance Tool that automates compliance checks and encourages continuous professional development, ensuring staff are updated with latest technology trends."
      },
      {
        "title": "React based Portfolio Web Application",
        "date": "June 2023",
        "description": "Developed a React-based portfolio web application to showcase my projects and skills, demonstrating my expertise in React, JavaScript, and CSS."
      },
      {
        "title": "Corporate Mass Emailer Tool (CMET)",
        "date": "July 2023",
        "description": "Developing a Python-based tool for automating the process of sending mass personalized emails. For Anniversaries, Birthdays, and other events."
        
      }
    
    
  ];

  const hoverBorderColor = useColorModeValue('blue.500', 'blue.200');
  const hoverScale = 1.10;
  const boxColor = useColorModeValue("white", "gray.800"); 
  const textColor = useColorModeValue("black", "white"); 
  const timelineElementBg = useColorModeValue("white", "gray.800"); 
  const timelineElementColor = useColorModeValue('rgb(33, 150, 243)', 'rgb(33, 150, 243)'); 
  const timelineElementBgBorder = useColorModeValue('rgb(33, 150, 243)', 'rgb(33, 150, 243)');

  

    return (
      <FullScreenSection id="Timeline" isDarkBackground background="linear-gradient(to bottom, #171923 0%,#567ea9 50%, #567ea9 100%)" pt={20}>
        <VerticalTimeline aria-label="Career timeline">
          {experiences.map((exp, index) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work" 
              dateClassName="vertical-timeline-element-date"
              key={exp.date}
              date={exp.date}
              iconStyle={{ background: timelineElementColor, color: '#ffff' }}
                >
              <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 5 }}
                textAlign="left"
                color={textColor} // Use the color mode value here
                backgroundColor={boxColor} // And here
                p={5}
                borderRadius="md"
                _hover={{
                  borderColor: hoverBorderColor,
                  transform: `scale(${hoverScale})`,
                  cursor: 'pointer',
                }}
                borderWidth={1}
                borderColor="transparent"
              >
                <h2 id={`exp-${index}-title`} color={textColor} aria-label={exp.title}>
                  {exp.title}
                </h2>
                <p id={`exp-${index}-description`} color={textColor} aria-label={exp.description}>
                  {exp.description}
                </p>
              </MotionBox>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </FullScreenSection>
    );
    };
  export default ResumeTimeline;