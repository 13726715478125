import { Tooltip, Badge, Box, Heading, VStack, useBreakpointValue, Grid } from '@chakra-ui/react';
import React from 'react';
import FullScreenSection from '../fullscreen/FullScreenSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNode, faPython, faHtml5, faCss3Alt, faJs, faReact, faJava, faMicrosoft, faGithub, faGithubAlt, faBitbucket, faGitAlt } from '@fortawesome/free-brands-svg-icons';
import { faC, faDatabase } from '@fortawesome/free-solid-svg-icons';
import info from './info.json';
// import "./index.css"
const techIcons = {
  "React": faReact,
  "JavaScript": faJs,
  "Express": faNode,
  "MongoDB": faDatabase,
  "Oracle SQL": faDatabase,
  "Python": faPython,
  "Java": faJava,
  "C/C++": faC,
  "HTML": faHtml5,
  "CSS": faCss3Alt,
  "Azure": faMicrosoft,
  "GitHub": faGithubAlt,
  "Git": faGitAlt,
  "Bitbucket": faBitbucket,
};

const { techs, techColors } = info;

const TechStackSection = () => {
  const templateColumns = useBreakpointValue({
    base: "repeat(1, 1fr)",
    sm: "repeat(2, 1fr)",
    md: "repeat(3, 1fr)",
    lg: "repeat(3, 1fr)",
    xl: "repeat(3, 1fr)",
  });

  return (
    <FullScreenSection
      id="tech-stack"
      isDarkBackground
      backgroundColor="gray.900"
      background = "linear-gradient(to bottom, #040d22 0%, #171923 100%)"
      // background = "linear-gradient(to bottom, #1529ea, #87ceeb)"

      py={20}
      spacing={10}
    >
      <Box>
        <Heading as="h1" id="tech-stack-heading" px="4" py="36" >
          Technologies I've Worked With
        </Heading>
      </Box>

      <Box 
        className="tech-stack-wrap"
        d="flex"
        flexWrap="wrap"
        gap={4}
      >
        <Grid 
          templateColumns={templateColumns} 
          columnGap={300} 
          rowGap={10} 
          width="100%" 
        >
          {techs.map((tech) => (
            <VStack 
              key={tech} 
              spacing={5} 
              align="stretch" 
              textAlign="center"
              data-testid={`tech-stack-${tech}`}
            >
              <Tooltip hasArrow label={info.techDescriptions[tech]} fontSize="md">
                <Box 
                  align="center"
                  padding="10px"
                  as="span"
                  _hover={{ transform: "scale(1.2)", textShadow: "0px 0px 15px rgba(255, 255, 255, 0.5)" }}
                  transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                  cursor="pointer"
                >
                  <FontAwesomeIcon icon={techIcons[tech]} size="5x" aria-label={tech} />
                </Box>
              </Tooltip>
              {/* <Box   display="flex" justifyContent="center" alignItems="center"> */}
              <Badge
                id="tech-stack-badge"
                px={5}
                py={1}
                justifyContent="center"
                alignContent="center"
                textAlign="center"
                variant="solid"
                colorScheme={techColors[tech] || 'gray'}
              >
                {tech}
              </Badge>
              {/* </Box> */}
            </VStack>
          ))}
        </Grid>
      </Box>
    </FullScreenSection>
  );
};

export default TechStackSection;
