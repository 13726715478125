import React from 'react';

const TermsOfUse = () => {
  return (
    <div style={{ 
      width: "100vw", 
      height: "100vh", 
      backgroundColor: "black", 
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center" 
    }}>
      <div style={{ color: "white", textAlign: "center", maxWidth: "45%" }}>
        <h1><strong>Terms of Use</strong></h1>
        <p>
          By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement. 
          In addition, when using this websites particular services, you shall be subject to any posted guidelines or rules applicable to such services. 
          Any participation in this service will constitute acceptance of this agreement. 
          If you do not agree to abide by the above, please do not use this service.
        </p>
        <p>
          As a condition of your use of this website, you warrant to the site owner that you will not use this website for any purpose that is unlawful or prohibited by these terms, conditions, and notices.
          This site and its components are offered for informational purposes only; this site shall not be responsible or liable for the accuracy, usefulness, or availability of any information transmitted or made available via the site, and shall not be responsible or liable for any error or omissions in that information.
        </p>
        <p>
          All content on this site, including but not limited to text, images, code, design, and layout, is the property of the site owner unless otherwise noted, and may not be used, copied, reproduced, or distributed without express written permission.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
