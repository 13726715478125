import React, { useState } from 'react';
import axios from 'axios';
import { Box, Input, Button, VStack, Text, Icon, HStack, Center, Flex } from "@chakra-ui/react";
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../GPTAssistant/chat.css"


// Get the current date and format it as a string
const now = new Date();
const formattedDate = now.toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

const Chat = () => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const clearImmediate = () => {
        setChatHistory([]);
    };

    const sendMessage = async () => {
        try {
            const response = await axios.post('/api/chat', { message });
            setChatHistory(chatHistory => [...chatHistory, { message: message, isUser: true }, { message: response.data, isUser: false }]);
            setMessage('');
        } catch (error) {
            console.error('Error while sending the message:', error.response?.data || error.message);
        }
    };

    return (
        <VStack align="stretch" spacing={4} bg="black" p={4} color="green.500" fontFamily="monospace" width="70%" borderWidth="1px" borderColor="gray.700" borderRadius="md">
            <Box color="white">
            <Icon as={FontAwesomeIcon} icon={faTerminal} mr={3} fontSize="32px" />
                Last login: {formattedDate} on user on APEPXA-{'>'}ttys0 
            </Box>
            <Box flexGrow={1} overflowY="auto" height={300} >
                {chatHistory.map((chat, index) => (
                    <Flex key={index} direction={chat.isUser ? "row-reverse" : "row"} p={2}>
                        <Box bg={chat.isUser ? "blue.500" : "transparent"} p={4} borderRadius="md" color={chat.isUser ? "black" : "green.500"} maxW="80%">
                            <Text as="span" fontWeight="bold">{chat.isUser ? 'You: ' : "'Thomas's Assitant: '"}</Text>
                            {chat.message}
                        </Box>
                    </Flex>
                ))}
            </Box>
            <div className="terminal-promptbox">
                <Input 
                     
                    value={message} 
                    onChange={(e) => setMessage(e.target.value)} 
                    placeholder= { "Type a message" }
                    bg="black"
                    color="green.500"
                    border="none"
                    _focus={{outline: "none"}}
                />
            </div>
            <HStack spacing={4} justifyContent={"center"}>
                <Button  width={300} backgroundColor={'blue.600'} color="white" onClick={sendMessage}>Send</Button>
                <Button width={300} backgroundColor={'blue.600'} color="white" onClick={clearImmediate}>Clear messsage</Button>
            </HStack>
        </VStack>
    );
};

export default Chat;
