import { Header, LandingSection, ProjectsSection, ContactMeSection, Footer, Alert, TechStackSection, MyAssistant, FooterAbout, ResumeTimeline, Certification } from "./components";
import "./home.css"
const Home = () => {

    return (
        <>
            <header>
                <Header />
            </header>
            <main>
                <LandingSection />
                <TechStackSection />
                <ResumeTimeline />
                <ProjectsSection />
                {/* Still work in progress */}
                <Certification />
                {/* <MyAssistant /> */}
                <FooterAbout />
            </main>
            <footer>
                <Footer />
                <Alert />
            </footer>
        </>
    );
};

export default Home;
